import React from "react"
import Layout from '../components/layout'
import SEO from "../components/seo"

function escapeRegExp(str) {
  return str.replace(/[-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}

function clean($input){
  var $find = `href="https://stirtingale.uk/platocms/`;
  var $replace = `href="/`;
  return $input.replace(new RegExp(escapeRegExp($find), 'g'), $replace);
}

function customseo_title($customseo_input,$page_title){
	if( $customseo_input != null ){
		return $customseo_input;
	} else {
		return $page_title;
	}
}

export default ({pageContext}) => (
	<Layout>
		<SEO 
		title={customseo_title(pageContext.acf.custom_title,pageContext.title)}
		description={pageContext.acf.custom_description}
		/>
		<div dangerouslySetInnerHTML={{__html:clean(pageContext.content)}} />
	</Layout>
);